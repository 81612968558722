//--------------------------------------------------------------------------------
// Global Header
//--------------------------------------------------------------------------------

.navbar {

  > .container-fluid .navbar-brand {
    margin: 0;
  }

  .navbar-brand:hover {
    color: #fff;
  }

  .nav.navbar-nav {
    display: table;
    margin: 0;
  }

  .nav.navbar-nav > li {
    display: table-cell;
  }

  .navbar-collapse .nav.navbar-nav,
  .navbar-collapse .nav.navbar-nav > li{
    display: block;
  }

  .nav.navbar-nav a,
  .navbar-toggle {
    margin: 0;
    padding: 15px 20px;
    border-radius: 0;
  }

  .navbar-toggle:before {
    display: inline-block;
    font: normal normal normal 18px/1 FontAwesome;
    content: "\F0D7";
  }

  .navbar-toggle:hover,
  .navbar-toggle:focus {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6;
  }

  .navbar-toggle .icon-bar {
    display: none;
  }

  .navbar-collapse {
    padding: 0;
  }

}

@media (min-width: 768px) {
  .navbar .container-fluid {
    padding: 0;
  }
}


@media (max-width: 768px) {
  // Hide the admin button on mobile
  .navbar .admin {
    display: none;
  }

  .nav.navbar-nav {
    width: 100%;
  }
}