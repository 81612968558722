.m0   { margin:          0;   }
.m5   { margin:          5px; }
.m10  { margin:         10px; }
.m15  { margin:         15px; }
.m20  { margin:         20px; }
.m25  { margin:         25px; }
.m30  { margin:         30px; }
.m40  { margin:         40px; }
.m50  { margin:         50px; }

.mt0  { margin-top:      0;   }
.mt5  { margin-top:      5px; }
.mt10 { margin-top:     10px; }
.mt15 { margin-top:     15px; }
.mt20 { margin-top:     20px; }
.mt25 { margin-top:     25px; }
.mt30 { margin-top:     30px; }
.mt40 { margin-top:     40px; }
.mt50 { margin-top:     50px; }

.mb0  { margin-bottom:   0;   }
.mb5  { margin-bottom:   5px; }
.mb10 { margin-bottom:  10px; }
.mb15 { margin-bottom:  15px; }
.mb20 { margin-bottom:  20px; }
.mb25 { margin-bottom:  25px; }
.mb30 { margin-bottom:  30px; }
.mb40 { margin-bottom:  40px; }
.mb50 { margin-bottom:  50px; }

.ml0  { margin-left:     0;   }
.ml5  { margin-left:     5px; }
.ml10 { margin-left:    10px; }
.ml15 { margin-left:    15px; }
.ml20 { margin-left:    20px; }
.ml25 { margin-left:    25px; }
.ml30 { margin-left:    30px; }
.ml40 { margin-left:    40px; }
.ml50 { margin-left:    50px; }

.mr0  { margin-right:    0;   }
.mr5  { margin-right:    5px; }
.mr10 { margin-right:   10px; }
.mr15 { margin-right:   15px; }
.mr20 { margin-right:   20px; }
.mr25 { margin-right:   25px; }
.mr30 { margin-right:   30px; }
.mr40 { margin-right:   40px; }
.mr50 { margin-right:   50px; }

.p0   { padding:         0;   }
.p5   { padding:         5px; }
.p10  { padding:        10px; }
.p15  { padding:        15px; }
.p20  { padding:        20px; }
.p25  { padding:        25px; }
.p30  { padding:        30px; }
.p40  { padding:        40px; }
.p50  { padding:        50px; }

.pt0  { padding-top:     0;   }
.pt5  { padding-top:     5px; }
.pt10 { padding-top:    10px; }
.pt15 { padding-top:    15px; }
.pt20 { padding-top:    20px; }
.pt25 { padding-top:    25px; }
.pt30 { padding-top:    30px; }
.pt40 { padding-top:    40px; }
.pt50 { padding-top:    50px; }

.pb0  { padding-bottom:  0;   }
.pb5  { padding-bottom:  5px; }
.pb10 { padding-bottom: 10px; }
.pb15 { padding-bottom: 15px; }
.pb20 { padding-bottom: 20px; }
.pb25 { padding-bottom: 25px; }
.pb30 { padding-bottom: 30px; }
.pb40 { padding-bottom: 40px; }
.pb50 { padding-bottom: 50px; }

.table > tbody > tr > td.p0{  padding: 0;  }