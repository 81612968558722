//--------------------------------------------------------------------------------
// act list for individual appointments
//--------------------------------------------------------------------------------

// force pattern column to be same width with and without icon
.box-blue span.pattern {
  width: 15px;;
}

.last-document {
  width: 95%;
  height:400px;
  margin-left: 10px;
  overflow: scroll;
}

.last-document-none {
  padding: 10px;
}
