.iconpicker{
  label{
    display:block;
  }
  ul.dropdown-menu{
    width: 320px;
    max-height: 200px;
    overflow-y: auto;
    text-align: center;
    & > li{
      display:inline;
      & > button, a {
        .btn;
        .btn-default;
        margin: 5px;

        & > .glyphicon, & > .fa, & > .ion{
          margin-right: 0;
        }
      }

      &.active > button, &.active > a{
        .btn-primary;
      }
    }
  }
}
