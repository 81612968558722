input.datepicker + ul {

  padding: 0 0 8px;
  margin: 0;
  border: 0 none;
  border-radius: 0;
  box-shadow: 0 2px 5px @gray;

  table {
    .btn {
      border-radius: 0;
      border-color: @panel-bg;
      border-left-width: 0;
      border-bottom-width: 0;
    }
  }
}

table.timepicker, .timepicker table,  table.ng-valid-time, table.ng-invalid-time{
  border-spacing: 5px 0;
  border-collapse: separate;
}