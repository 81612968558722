//--------------------------------------------------------------------------------
// notifications
//--------------------------------------------------------------------------------

.humane,
.humane-libnotify {
    top: 0;
    left: 30%;
    right: 10%;
    padding: 15px;
    width: 60%;
    color: @color-white;
    background: @color-gray-dark;
    border-radius: 0;
}

.humane.humane-error{
    padding: 30px;
    color: @black;
    background: @red;
}
