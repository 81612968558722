//--------------------------------------------------------------------------------
// user profile
//--------------------------------------------------------------------------------

.profile {

  .header {
    border-color: #00c0ef;

      .box-header {
        background-color: #00c0ef;
        h2 {
          margin: 0;
        }
      }

      .box-body {
        position: relative;
        top: -40px;
        h4 {
          margin-bottom: 0;
        }
      }
  }

  .body {
    border-color: #00c0ef;
    button, .password {
      margin-top: 20px;
    }
  }

}