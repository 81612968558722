.u-switch {
  display: inline-block;
  position: relative;
  width: 48px;
  height: 28px;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  margin-right:8px;
}
.u-switch input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.u-switch .track {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 14px;
  background-color: #cccccc;
}
.u-switch .button {
  position: absolute;
  top: 2px;
  bottom: 2px;
  right: 22px;
  left: 3px;
  background-color: #fff;
  border-radius: 18px;
  transition: all 100ms ease;
  -webkit-transition: all 100ms ease;
}
.u-switch.is-checked .track {
  background-color: #5faee3;
}
.u-switch.is-checked .button {
  right: 2px;
  left: 22px;
}
