/*
  Flaticon icon font: Flaticon
  Creation date: 15/02/2016 19:33
  */

@font-face {
    font-family: Flaticon;
    src: url("./Flaticon.eot");
    src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
    url("./Flaticon.woff") format("woff"),
    url("./Flaticon.ttf") format("truetype"),
    url("./Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: Flaticon;
        src: url("./Flaticon.svg#Flaticon") format("svg");
    }
}

[class^="flaticon-"], [class*=" flaticon-"]{
    display: inline-block;
    font: normal normal normal 14px/1 Flaticon;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sidebar-menu .treeview-menu > li > a > [class^="flaticon-"],
.sidebar-menu .treeview-menu > li > a > [class*=" flaticon-"] {
    width: 20px;
}

.flaticon-alarm:before { content: "\f100"; }
.flaticon-ankle:before { content: "\f101"; }
.flaticon-arm:before { content: "\f102"; }
.flaticon-back:before { content: "\f103"; }
.flaticon-bandage:before { content: "\f104"; }
.flaticon-blood-pressure:before { content: "\f105"; }
.flaticon-brain:before { content: "\f106"; }
.flaticon-brain-1:before { content: "\f107"; }
.flaticon-cast:before { content: "\f108"; }
.flaticon-chart:before { content: "\f109"; }
.flaticon-chart-1:before { content: "\f10a"; }
.flaticon-clipboard:before { content: "\f10b"; }
.flaticon-culture:before { content: "\f10c"; }
.flaticon-cut:before { content: "\f10d"; }
.flaticon-cut-hand:before { content: "\f10e"; }
.flaticon-dna:before { content: "\f10f"; }
.flaticon-ear:before { content: "\f110"; }
.flaticon-eye:before { content: "\f111"; }
.flaticon-eye-1:before { content: "\f112"; }
.flaticon-eyes:before { content: "\f113"; }
.flaticon-feet:before { content: "\f114"; }
.flaticon-foot:before { content: "\f115"; }
.flaticon-foot-bones:before { content: "\f116"; }
.flaticon-fracture:before { content: "\f117"; }
.flaticon-glasses:before { content: "\f118"; }
.flaticon-hand-bones:before { content: "\f119"; }
.flaticon-hazard:before { content: "\f11a"; }
.flaticon-health-conversation:before { content: "\f11b"; }
.flaticon-heart:before { content: "\f11c"; }
.flaticon-hip:before { content: "\f11d"; }
.flaticon-intestine:before { content: "\f11e"; }
.flaticon-joint:before { content: "\f11f"; }
.flaticon-kidney:before { content: "\f120"; }
.flaticon-knee-bones:before { content: "\f121"; }
.flaticon-leg:before { content: "\f122"; }
.flaticon-legs:before { content: "\f123"; }
.flaticon-liver:before { content: "\f124"; }
.flaticon-lungs:before { content: "\f125"; }
.flaticon-lungs-1:before { content: "\f126"; }
.flaticon-maternity:before { content: "\f127"; }
.flaticon-medical:before { content: "\f128"; }
.flaticon-mouth:before { content: "\f129"; }
.flaticon-nose:before { content: "\f12a"; }
.flaticon-pharmacy:before { content: "\f12b"; }
.flaticon-pill:before { content: "\f12c"; }
.flaticon-pregnancy:before { content: "\f12d"; }
.flaticon-prosthetic:before { content: "\f12e"; }
.flaticon-radiation:before { content: "\f12f"; }
.flaticon-reproductive:before { content: "\f130"; }
.flaticon-ribs:before { content: "\f131"; }
.flaticon-scale:before { content: "\f132"; }
.flaticon-screw:before { content: "\f133"; }
.flaticon-shot:before { content: "\f134"; }
.flaticon-skull:before { content: "\f135"; }
.flaticon-spine:before { content: "\f136"; }
.flaticon-stethoscope:before { content: "\f137"; }
.flaticon-stomach:before { content: "\f138"; }
.flaticon-teeth:before { content: "\f139"; }
.flaticon-throat:before { content: "\f13a"; }
.flaticon-toe:before { content: "\f13b"; }
.flaticon-tongue:before { content: "\f13c"; }
.flaticon-tool:before { content: "\f13d"; }
.flaticon-tooth:before { content: "\f13e"; }
.flaticon-transport:before { content: "\f13f"; }
.flaticon-transport-bed:before { content: "\f140"; }
.flaticon-urinary:before { content: "\f141"; }
.flaticon-weight:before { content: "\f142"; }
.flaticon-wheelchair:before { content: "\f143"; }
.flaticon-x-ray:before { content: "\f144"; }
.flaticon-x-ray-1:before { content: "\f145"; }
