.control-container {
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-top-width: 3px;
  border-radius: 3px;
}

.control-container > div > .row {
  border-top: 1px solid #ccc;
  margin-left: -10px;
  margin-right: -10px;
  padding-top: 10px;
}

.control-container.no-label > div > .row {
  border-top: none;
}

.control-container > div > .row.control-editor{
  border-top: 1px dotted #3c8dbc;
}

.control-container > div > .row.control-editor {
  margin-left: 0;
  margin-right: 0;
}

.container-controls {
  float: right;
}

.container-label {
  display: inline-block;
  font-size: 18px;
  margin-bottom: 10px;
}

.row .group-label, .row .control-label {
  font-size: 14px;
  margin-bottom: 5px;
}

.row label {
  margin-bottom: 2px;
  font-weight: normal;
  font-size: 12px;
}

.control-download-button-enabled{
  border: 0px;
  background-color: transparent;
}

.control-download-button-disabled{
  color: gray;
  border: 0px;
  background-color: transparent;
}

.control-upload-button-disabled{
  border: 0px;
  background-color: transparent;
}

.control-upload-button-disabled-graycolor{
  color: gray;
  border: 0px;
  background-color: transparent;
}

.control-upload-button-enabled{
  border: 0px;
  background-color: transparent;
}

.anchor-not-active {
  pointer-events: none;
  cursor: default;
 }