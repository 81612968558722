//--------------------------------------------------------------------------------
// sidebar
//--------------------------------------------------------------------------------

.skin-blue .sidebar-menu > li > .treeview-menu {
  background: none;
}

.skin-blue .sidebar h4,
.skin-blue .sidebar a {
  color: #b8c7ce;
}

.skin-blue .sidebar-menu li:hover > a,
.skin-blue .sidebar-menu li.active > a {
  background: #475f68;
}

.skin-blue .sidebar-menu > li.header {
  background: none;
}

.sidebar-menu > li > a,
.sidebar-menu .treeview-menu > li > a {
  padding: 10px 5px 10px 10px;
  font-size: 13px;
  line-height: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar-menu .treeview-menu .treeview-menu > li > a {
  padding: 8px 5px 8px 10px;
}

.sidebar-menu .treeview-menu {
  padding-left: 0;
}

.sidebar-menu .treeview-menu .treeview-menu {
  padding-left: 10px;
}

.sidebar-menu li.header {
  padding: 10px;
  border-top: 1px solid #475f68;

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }

  a {
    display: block;
  }
}

// Allow sidebar on small screens
@media (max-width: 767px) {
  .sidebar-mini.sidebar-collapse .main-sidebar {
    -webkit-transform: translate(-250px, 0);
    -ms-transform: translate(-250px, 0);
    -o-transform: translate(-250px, 0);
    transform: translate(-250px, 0);
  }

  .main-sidebar, .left-side {
    -webkit-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none;
    padding-top: 50px;
  }
}