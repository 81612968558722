//--------------------------------------------------------------------------------
// patient header
//--------------------------------------------------------------------------------

.patient-header {
  padding: 0 0 10px 0;

  i {
    margin: 0 10px 0 0;
    width: 30px;
    font-size: 20px;
    text-align: center;
    color: #B3B7BF;
  }
}

.patient-header__col-1 {
}

.patient-header__col-2 {
  text-align: right;
}

.patient-header__card {
}

.patient-header__card__media {
  float: left;
  margin: 0 20px 0 0;
}

.patient-header__card__content {
  float: left;
}

i.patient-header__gender {
  width: auto;
  margin: 5px 0 0 0;
  font-size: 40px;
}

.patient-header__name {
  font-size: 20px;
}

.patient-header__desc {
}

.patient-header__allergies {

  i {
    color: #dd4b39;
  }
}

.patient-header__date {
  margin: 5px 0 0 0;
  color: #999;
}

.patient-header-copy {
  padding: 0 0 10px 0;

  i {
    margin: 0 10px 0 0;
    width: 50px;
    font-size: 40px;
    text-align: right;
    color: #B3B7BF;
  }
}