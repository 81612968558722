@import "~bootstrap/less/bootstrap.less";
@import "~admin-lte/build/less/core.less";
@import "~admin-lte/build/less/variables.less";
@import "~admin-lte/build/less/mixins.less";
@import "~admin-lte/build/less/header.less";
@import "~admin-lte/build/less/sidebar.less";
@import "~admin-lte/build/less/sidebar-mini.less";
@import "~admin-lte/build/less/control-sidebar.less";
@import "~admin-lte/build/less/dropdown.less";
@import "~admin-lte/build/less/forms.less";
@import "~admin-lte/build/less/progress-bars.less";
@import "~admin-lte/build/less/small-box.less";
@import "~admin-lte/build/less/boxes.less";
@import "~admin-lte/build/less/info-box.less";
@import "~admin-lte/build/less/timeline.less";
@import "~admin-lte/build/less/buttons.less";
@import "~admin-lte/build/less/callout.less";
@import "~admin-lte/build/less/alerts.less";
@import "~admin-lte/build/less/navs.less";
@import "~admin-lte/build/less/products.less";
@import "~admin-lte/build/less/table.less";
@import "~admin-lte/build/less/labels.less";
@import "~admin-lte/build/less/direct-chat.less";
@import "~admin-lte/build/less/users-list.less";
@import "~admin-lte/build/less/carousel.less";
@import "~admin-lte/build/less/modal.less";
@import "~admin-lte/build/less/mailbox.less";
@import "~admin-lte/build/less/lockscreen.less";
@import "~admin-lte/build/less/login_and_register.less";
@import "~admin-lte/build/less/404_500_errors.less";
@import "~admin-lte/build/less/invoice.less";
@import "~admin-lte/build/less/bootstrap-social.less";
@import "~admin-lte/build/less/fullcalendar.less";
@import "~admin-lte/build/less/select2.less";
@import "~admin-lte/build/less/miscellaneous.less";
@import "~admin-lte/build/less/print.less";
@import "~open-sans-fontface/open-sans.less";
@import "~font-awesome/less/font-awesome.less";
@import "./skin-blue.less";
@import "./toggle-switch.less";
@import "./loader.less";
@import "./iconpicker.less";
@import "~humane-js/themes/libnotify.css";
@import "./flaticon-medical/flaticon.css";
@import "./slickmap.less";

/* FOR DASHBOARD DEMO ONLY */
@import "https://cdnjs.cloudflare.com/ajax/libs/ionicons/2.0.1/css/ionicons.min.css";

@boxed-layout-bg-image-path: "../../dist/img/boxed-bg.jpg";
@input-color: #555;
@sidebar-width: 250px;

@import "./toggle-switch.less";
@import "./margin-padding.less";


@import "~react-datetime/css/react-datetime.css";
@import "./datepicker.less";

@import "~react-bootstrap-table/css/react-bootstrap-table.css";
.react-bs-table{
  border: none;
}

/* OVERRIDES */

.container {
  margin-left:0;
}

.glyphicon{
  // Not sure why we need this atm...
  line-height: inherit;
}

.main-header .logo {
  height: 50px;
}

.sidebar{
  font-size:13px;
}

.sidebar-menu ul.menu-open{
  display:block;
}

body {
  font-family: 'Open Sans', 'Segoe UI', Tahoma, Arial, sans-serif;
}

.content-wrapper {
  padding-top: 50px;
}

.sidebar-wrapper {
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: 10;
}

.main-header .logo{
  position: fixed;
}

.ql-toolbar {
  background-color: #eee;
}
.markdown{
  background-color: #fff;
  padding:15px;
}

div.CodeMirror, .document-preview{

  height: 70vh;
  //min-height: 60px;
  border:1px solid #ccc;

  border-radius:3px;
}


.main-sidebar{
  height: 100%;
  overflow: auto;
}


.cm-s-disabled {
  background-color: @gray !important;
  opacity: 0.75;
}

.btn-block{
  max-width:500px;
}

.show-hand {
  cursor: pointer;
}

.no-doctor-specialty {
  opacity: 0.50;
}

.rdt:after {
  content: @fa-var-calendar;
  font: normal normal normal @fa-font-size-base/@fa-line-height-base FontAwesome;
  position:absolute;
  right:10px;
  top:0;
  line-height: 32px;
}

.study-flag {
  color: #4ECA79;
}

.patient-chart-status {
  font-style: italic;
  color: rgb(148, 145, 145);
}
//--------------------------------------------------------------------------------
// global
//--------------------------------------------------------------------------------

.group {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.border-bottom{
  border-bottom:1px solid #333;
}

//--------------------------------------------------------------------------------
// vars
//--------------------------------------------------------------------------------

@color-gray: #ccc;
@color-gray-dark: #555;
@color-white: #fff;
@red: #dd4b39;

@black: #111;

@import "./header.less";
@import "./sidebar.less";
@import "./controls.less";
@import "./notifications.less";
@import "./patient-header.less";
@import "./breadcrumb.less";
@import "./appointment-acts.less";
@import "./login.less";
@import "./profile.less";
@import "./autosuggest.less";

//--------------------------------------------------------------------------------
// pattern-modal-button
//--------------------------------------------------------------------------------

